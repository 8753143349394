import React from 'react';
import styled from '@emotion/styled';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { cvLinks } from './Cv-links';

/*---Material---*/
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';

/*---Components---*/
import { LinkComponent } from '../../components/linkComponent';

/*---Objects---*/
import { CvtColorSchema } from '@conventic-web/internal.global';
import { CssEffectObj } from '../../global/sharedObjects';



const NoExtendWrapper = styled.div`
  width: 100%;
  background-color: ${CvtColorSchema.green};
  color: ${CvtColorSchema.white};
  background-image: linear-gradient(
    ${CvtColorSchema.green},
    ${CvtColorSchema.green}
  );
  ${CssEffectObj.skew}
  ${CssEffectObj.hoverEffekt}
  &:hover {
    background-image: linear-gradient(
      ${CvtColorSchema.yellow},
      ${CvtColorSchema.yellow}
    );
  }
`;

const NoExtendWrapperUnskew = styled.div`
  ${CssEffectObj.unskew}
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const CvMainPage = () => {
  const styles = {
    root: {
      marginTop: '1em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '0 5%',
    },

    containerBtnTitle: {
      fontWeight: 600,
      padding: '1.5em',
      cursor: 'pointer',
    },
    containerIcons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '0.5em',
    },
  };

  return (
    <>
      {cvLinks.map((ele: any) => {
        return (
          <Box component="div" sx={styles.root} key={ele.id}>
            <LinkComponent
              linkTo={SYSTEM_STRINGS.LINKS.INTERN.CV + '/' + ele.id}
            >
              <NoExtendWrapper>
                <NoExtendWrapperUnskew>
                  <Box component="div" sx={styles.containerBtnTitle} id={ele.id}>
                    {ele.id}
                  </Box>
                  <Box component="div" sx={styles.containerIcons}>
                    <AddIcon style={{ fontSize: 60 }} />
                  </Box>
                </NoExtendWrapperUnskew>
              </NoExtendWrapper>
            </LinkComponent>
          </Box>
        );
      })}
    </>
  );
};
